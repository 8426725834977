$font-family-sans-serif:  "MYRIADPROREGULAR", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$bg-ri: #8a1002;
$container-large-desktop:      (1320px) !default;
@import "theme/variables";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "theme/bootswatch";
@import "icon-font";
@import "fonts";
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
@import url(https://fonts.googleapis.com/css?family=Oswald);
@import "selos";
@import "titulos";
@import "blog/posts";
.carousel-caption{
  left: 0;
  right: 0;
  background: rgba(100, 2, 0, 0.85);
  width: 100%;
}
.panel-4rigo {
  @include panel-variant($bg-ri, #e6e6e6, $bg-ri, $bg-ri);
  .panel-heading{

  }
  .panel-body{
    ul{
      margin-bottom: 0px;
      li{
        padding-bottom: 7px;
        a{
          &:hover, &:focus{
            text-decoration: none;
            color: #2F0908;
            font-weight: 700;
            span{
              font-weight: 700;
            }
          }
          span{
            margin-right: 5px;
          }
          font-size: 16px;
        }
      }
    }
  }
}
.icon{
  position: relative;
  top: 2px;
}
.logo{
  max-width: 438px;
  max-height: 72px;
  //background: red;
  //background: url(../images/logo-cartorio-modelo.png);
}
section.topo > nav.menu-site > div.red-top {
  border-top: 5px solid $bg-ri;
  border-top-left-radius: 5px
}

.topo > div.bg-topo {
  background: url(../images/7e3a8f28dfedf2cfbe0a112218c47b2a-copy-3.png);
  height: 201px;
  width: 470px;
  background-repeat: no-repeat;
  position: absolute;
  top: 0px;
  right: 0px;
  border-bottom: 5px solid $bg-ri;
  border-top-left-radius: 5px;
}

section.topo {
  background: url(../images/default_slider_bg.png);
  height: 285px;
  width: 100%;
  top: 64px;
  padding-top: 64px;
}

//.menu-site {
//  background: -webkit-linear-gradient(90deg, #e9e9e9 0%, #f9fafb 100%);
//  background: -moz-linear-gradient(90deg, #e9e9e9 0%, #f9fafb 100%);
//  background: -o-linear-gradient(90deg, #e9e9e9 0%, #f9fafb 100%);
//  background: -ms-linear-gradient(90deg, #e9e9e9 0%, #f9fafb 100%);
//  background: linear-gradient(0deg, #e9e9e9 0%, #f9fafb 100%);
//  box-shadow: -1px 1px 3px 0.00px rgba(0, 0, 0, 0.21);
//  height: 79px;
//  margin-top: 60px;
//  width: 100%;
//   ul{
//      list-style: none;
//      padding: 25px 0 0 0;
//      > li {
//        display: inline-block;
//        position:relative;
//        > ul{
//          display: none;
//          position:absolute;
//          top:100%;
//          left:0;
//          padding:0;
//          li{
//            display: inline-block;
//          }
//        }
//        &:hover{
//          ul{
//            display: block;
//          }
//        }
//      }
//      > li.active{
//        a{
//          color: #B25147;
//        }
//      }
//      > li:after{
//        content: "";
//        background: url(../images/1.png);
//        height: 44px;
//        width: 2px;
//        position: absolute;
//        display: inline-block;
//        margin-top: -33px;
//        margin-left: -23px;
//      }
//      > li > a {
//        font: 400 16px Montserrat;
//        color: #7a828b;
//        outline: 0;
//        width: 47px;
//        text-decoration: none;
//        padding-right: 44px;
//      }
//  }
//}

@import "menu";



.fone{
  position: relative;
}
.fone:before{
  content: "";
  background: url(../images/call-icon.png);
  height: 27px;
  width: 23px;
  position: absolute;
  display: inline-block;
  bottom: 8px;
  left: -19px;
}
.fone{
  background: url(../images/Shape-1.png);
  width: 158px;
  height: 45px;
  margin-top: 23px;
}

p.title-mine {
  color: #8a1002;
  font: 400 12.11px Montserrat;
  text-align: center;
  position: absolute;
  top: -7px;
  right: 21px;
}

.fone-text {
  font: 400 20.07px / 61.577px Montserrat;
  color: #7a828b;
  text-align: center;
  position: absolute;
  top: -7px;
  left: 35px;
}
.fone-text > small{
  font-size: 13px;
  margin-right: 5px;
  position: absolute;
  left: -17px;
  top: -3px;
}

.busca > .lupa {
  background: url(../images/buscar-animar.png);
  width: 160px;
  height: 30px;
}

.title-busca {
  font: 400 15.11px Montserrat;
  color: #7a828b;
}
.title-busca:before {
  content: "| ";
  font: 400 15.11px Montserrat;
}

.busca {
  margin-top: 12px;
}

section.header {
  background: url(../images/banner.jpg) no-repeat;
  height: 562px;
  width: 100%;

}

.noticias {
  background-color: #7a828b;
  width: 100%;
  height: 400px;
}

.left-img {
  background: url(../images/left-new-cart.png);
  height: 580px;
  width: 100%;
}

.parceiros {
  height: 300px;
}

.titulo-parceiros {
  color: #ff0000;
  font: 400 26.13px / 61.577px Montserrat;
  text-shadow: 0px 2px 1px rgba(114, 95, 71, 0.32);
  height: 60px;
  width: 234px;
  border-radius: 10px;
  border: 2px solid #7d868e;
  padding: 0px 0px 0px 37px;
  position: relative;
  margin: 0 auto;
  margin-top: 23px;
}
.titulo-parceiros:before{
  content: '|';
  display: inline-block;
  top: -45px;
  left: 45%;
  position: absolute;
  height: 23px;
  color: #7c858e;
}

.locale {

}

.locale {
  background: url(../images/Ellipse-4.png);
  height: 34px;
  width: 22px;
}

.parceiros-logo {
  min-height: 154px;
  position: relative;
}
.parceiros-logo > img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.parceiros-logos {
  padding: 30px 0 30px 0;
}

.subfooter {
  width: 100%;
  height: 65px;
  background: $bg-ri;
  position: relative;
  margin-bottom: 10px;
  overflow: hidden;
}

.e-mail-cartorio {
  color: #ffffff;
  font: 400 14px / 48px Montserrat;
  line-height: 65px;
  position: relative;
  padding-left: 45px;
}
.e-mail-cartorio:before{
  content: "";
  display: inline-block;
  height: 22px;
  width: 32px;
  background: url(../images/mail.png);
  position: absolute;
  left: 0;
  top: 21px;
}

.cop {
  color: #ffffff;
  font: 400 14px / 48px Montserrat;
  line-height: 65px;
}

.acesso-menu > p {
  color: #7c858e;
  text-transform: uppercase;
  font: 700 14px / 48px Montserrat;
  border-bottom: $bg-ri solid 2px;
  line-height: 30px;
  border-bottom-width: 2px;
}

.acesso-menu > ul {
  padding-left: 15px;
  margin-top: -5px;
}
.acesso-menu > ul > li{
  &.active{
    a{
      color: $bg-ri;
    }
  }
  padding-left: 0px;
  color: $bg-ri;
  font: 700 12px / 25px Montserrat;
}

.acesso-menu > ul > li > a{
  color: #7c858e;
  font: 700 12px / 25px Montserrat;
}
section#layout-content, section#topo, footer#layout-footer{
  clear: both;
}
@import "news";
@import "theme/page";


