.selos{
  .amb{
    width: 72px;
    height: 72px;
  }
  .abnt{
    width: 66px;
    height: 66px;
  }
  .pqta{
    width: 66px;
    height: 66px;
  }
}