.menu-site
{
  background: -webkit-linear-gradient(90deg, #e9e9e9 0%, #f9fafb 100%);
  background: -moz-linear-gradient(90deg, #e9e9e9 0%, #f9fafb 100%);
  background: -o-linear-gradient(90deg, #e9e9e9 0%, #f9fafb 100%);
  background: -ms-linear-gradient(90deg, #e9e9e9 0%, #f9fafb 100%);
  background: linear-gradient(0deg, #e9e9e9 0%, #f9fafb 100%);
  box-shadow: -1px 1px 3px 0.00px rgba(0, 0, 0, 0.21);
  height: 79px;
  margin-top: 60px;
  width: 100%;

  //margin-top:15px
}

.menu-site ul
{
  list-style:none;
  position:relative;
  float:left;
  margin:0;
  padding: 0;
}
.menu-site ul:first-child > li.active{
  //> a:first-child {
  //    padding-left: 0px;
  //    padding-right: 25px;
  //}
}
.menu-site ul > li:not(:first-child){
  a{
    background-image: url('../images/1.png');
    background-position: left;
    background-repeat: no-repeat;
    padding-right: 25px;
    padding-left:  25px;
  }
}
.menu-site ul a
{
  display:table-cell;
  color: #7a828b;
  text-decoration:none;
  line-height:32px;

  font: 400 15px Montserrat;
  outline: 0;
  height: 44px;
  vertical-align: middle;
  padding:0 15px;


  //display:block;
  //font: 400 16px Montserrat;
  //color: #7a828b;
  //outline: 0;
  //width: 47px;
  //text-decoration: none;
  //padding:0 15px;
  //padding-right: 44px;
}

.menu-site ul li
{
  position:relative;
  float:left;
  margin:0;
  padding: 15px 0 15px;
}

.menu-site ul >{
  li.active, li.child-active{
    > a {
      color: $bg-ri;
    }
  }
}

li:hover > a
{
  //background:#f6f6f6
  color: $bg-ri;
}

.menu-site ul ul
{
  display:none;
  position:absolute;
  top: 100%;
  left:0;
  background: #eaeaea;
  margin:0;
  padding:0;
  z-index: 1000;
}

.menu-site ul ul li
{
  float:none;
  padding: 0;
  min-width: 260px;
  border: 1px none $bg-ri;
  border-bottom-style: solid;

}

.menu-site ul ul a
{
  line-height:120%;
  padding:10px 15px
}

.menu-site ul ul ul
{
  top:0;
  left:100%
}

.menu-site ul li:hover > ul
{
  display:block
}

// Reposive Menu
.nav-toggle {
  //position: absolute;
  top: 0;
  right: 15px;
  z-index: 999999;
  padding: 10px 35px 16px 0;
  cursor: pointer; }

.nav-toggle:focus {
  outline: none; }

.nav-toggle span, .nav-toggle span:before, .nav-toggle span:after {
  content: "";
  position: absolute;
  display: block;
  width: 35px;
  height: 3px;
  border-radius: 1px;
  background: $bg-ri;
  cursor: pointer; }

.nav-toggle span:before {
  top: -10px; }

.nav-toggle span:after {
  bottom: -10px; }

.nav-toggle span, .nav-toggle span:before, .nav-toggle span:after {
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out; }

.nav-toggle.active span {
  background-color: transparent; }

.nav-toggle.active span:before, .nav-toggle.active span:after {
  top: 0; }

.nav-toggle.active span:before {
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

.nav-toggle.active span:after {
  top: 10px;
  -ms-transform: translatey(-10px) rotate(-45deg);
  -webkit-transform: translatey(-10px) rotate(-45deg);
  transform: translatey(-10px) rotate(-45deg); }

@media (max-width: 768px) {
  section.topo{
    background: transparent;
    padding-top: 0px;
    height: 90px;
    .bg-topo{
      background: transparent;
      height: 90px;
      border-bottom: none;
    }
    .navicon{
      padding-top: 12px;
      float: left;
      width: 100%;
      height: 90px;
    }
    nav.menu-site{
      margin-top: 0px;
    }
  }
  .fone{
    display: none;
  }
  .busca{
    display: none;
  }
  .logo{
    display: none;
  }
  .logo-resposive{
    max-width: 300px;
  }
  .nav-toggle{
    padding-top: 22px;
    float: right;
    margin-right: 7px;
  }
}