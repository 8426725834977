section.home{
  margin-top: 10px;
  margin-bottom: 15px;
}

.panel.panel-4rigo{
  //.panel-body{
  //  padding: 0px;
  //}
}

.titulo-bordo{
  color: #640200;
}