.page-header-ri{
  float: left;
  width: 100%;
  padding-bottom: 15px;
  margin-top: -15px;
  h3{
    margin-bottom: 0;
    font-weight: 700;
    letter-spacing: 1px;
    color: #333;
    font-family: 'Open Sans', sans-serif;
    span.subitulo{
      display: block;
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 6px;
    }
  }
  &:after{
    content: '';
    display: block;
    margin-top: 2px;
    width: 40px;
    border-top: 2px solid #8A1002;
  }
}