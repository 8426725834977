section.news{
  background-color: #7a828b;
  min-height: 411px;
}
.redes{
    float: left;
    p.title{
      color: #7c858e;
      text-transform: uppercase;
      font: 700 14px / 48px Montserrat;
      line-height: 27px;
      text-align: center;
    }
    .social{
      float: left;
      margin-right: 5px;
      i.fa{
        font-size: 14pt;
        color: $bg-ri;
        border: solid 1px $bg-ri;
        height: 28px;
        width: 28px;
        text-align: center;
        vertical-align: middle;
        display: table-cell;
      }
    }
}

.endereco{
  float: left;
  margin-top: 7px;
  position: relative;
  padding-left: 35px;
  p{
    color: #7c858e;
    font: 400 13px / 20px Montserrat;
    margin-bottom: -3px;
  }
  &:before{
    content: "\f278";
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-size: 25px;
    color: $bg-ri;
    position: absolute;
    top: 5px;
    left: 0px;
  }
}